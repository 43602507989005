#animation {
  visibility: hidden;
}

.animation {
  visibility: visible !important;
  animation-fill-mode: both !important;
}

.replay {
  animation-iteration-count: infinite !important;
}

.delay-05s {
  animation-delay: .5s !important;
}

.delay-1s {
  animation-delay: 1s !important;
}

.delay-1-5s {
  animation-delay: 1.5s !important;
}

.delay-2s {
  animation-delay: 2s !important;
}

.delay-3s {
  animation-delay: 3s !important;
}

.fade-in {
  animation: 1.2s ease-in-out fade-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: 1s ease-out fade-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in-right {
  animation: 1s ease-out fade-in-right;
}

@-webkit-keyframes fade-in-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.fade-in-left {
  animation: 1s ease-out fade-in-left;
}

@-webkit-keyframes fade-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.fade-in-up {
  animation: 1s ease-out fade-in-up;
}

@-webkit-keyframes fade-in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fade-in-down {
  animation: 1s ease-out fade-in-down;
}

@-webkit-keyframes fade-in-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fade-out-right {
  animation: 1s ease-out fade-out-right;
}

@-webkit-keyframes fade-out-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
}

@keyframes fade-out-right {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.fade-out-left {
  animation: 1s ease-out fade-out-left;
}

@-webkit-keyframes fade-out-left {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
}

@keyframes fade-out-left {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.fade-out-up {
  animation: 1s ease-out fade-out-up;
}

@-webkit-keyframes fade-out-up {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
}

@keyframes fade-out-up {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.fade-out-down {
  animation: 1s ease-out fade-out-down;
}

@-webkit-keyframes fade-out-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}

@keyframes fade-out-down {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.slide-in-right {
  animation: 1.2s slide-in-right;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(-2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: 1.2s slide-in-left;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slide-in-up {
  animation: 1.2s slide-in-up;
}

@-webkit-keyframes slide-in-up {
  0% {
    -webkit-transform: translateY(1000px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes slide-in-up {
  0% {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slide-in-down {
  animation: 1.2s slide-in-down;
}

@-webkit-keyframes slide-in-down {
  0% {
    -webkit-transform: translateY(-1000px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-1000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slide-out-right {
  animation: 1.5s slide-out-right;
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(2000px);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(2000px);
  }
}

.slide-out-left {
  animation: 1.5s slide-out-left;
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-2000px);
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-2000px);
  }
}

.slide-out-up {
  animation: 1.2s slide-out-up;
}

@-webkit-keyframes slide-out-up {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-1000px);
  }
}

@keyframes slide-out-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-1000px);
  }
}

.slide-out-down {
  animation: 1.2s slide-out-down;
}

@-webkit-keyframes slide-out-down {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(1000px);
  }
}

@keyframes slide-out-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(1000px);
  }
}

.bounce-in {
  animation: 1.2s ease-out bounce-in;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  25% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(.9);
  }

  70% {
    opacity: 1;
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  90% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(.9);
  }

  25% {
    -webkit-transform: scale(1.2);
  }

  40% {
    -webkit-transform: scale(.8);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  60% {
    -webkit-transform: scale(.9);
  }

  70% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }

  80% {
    -webkit-transform: scale(.95);
  }

  90% {
    -webkit-transform: scale(1.01);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.bounce-in-right {
  animation: 1.2s ease-out bounce-in-right;
}

@keyframes bounce-in-right {
  0% {
    transform: translateX(-2000px);
  }

  63% {
    transform: translateX(30px);
  }

  70%, 80%, 90%, 100% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(14px);
  }

  85% {
    transform: translateX(8px);
  }

  95% {
    transform: translateX(4px);
  }
}

@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(-2000px);
  }

  63% {
    -webkit-transform: translateX(30px);
  }

  70%, 80%, 90%, 100% {
    -webkit-transform: translateX(0);
  }

  75% {
    -webkit-transform: translateX(14px);
  }

  85% {
    -webkit-transform: translateX(8px);
  }

  95% {
    -webkit-transform: translateX(4px);
  }
}

.bounce-in-left {
  animation: 1.2s ease-out bounce-in-left;
}

@keyframes bounce-in-left {
  0% {
    transform: translateX(2000px);
  }

  63% {
    transform: translateX(-30px);
  }

  70%, 80%, 90%, 100% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(-14px);
  }

  85% {
    transform: translateX(-8px);
  }

  95% {
    transform: translateX(-4px);
  }
}

@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(2000px);
  }

  63% {
    -webkit-transform: translateX(-30px);
  }

  70%, 80%, 90%, 100% {
    -webkit-transform: translateX(0);
  }

  75% {
    -webkit-transform: translateX(-14px);
  }

  85% {
    -webkit-transform: translateX(-8px);
  }

  95% {
    -webkit-transform: translateX(-4px);
  }
}

.bounce-in-up {
  animation: 1.2s ease-out bounce-in-up;
}

@keyframes bounce-in-up {
  0% {
    transform: translateY(1000px);
  }

  25% {
    transform: translateY(50px);
  }

  30%, 55%, 70%, 80%, 90%, 100% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(25px);
  }

  63% {
    transform: translateY(15px);
  }

  75% {
    transform: translateY(7px);
  }

  85% {
    transform: translateY(4px);
  }

  95% {
    transform: translateY(2px);
  }
}

@-webkit-keyframes bounce-in-up {
  0% {
    -webkit-transform: translateY(1000px);
  }

  25% {
    -webkit-transform: translateY(50px);
  }

  30%, 55%, 70%, 80%, 90%, 100% {
    -webkit-transform: translateY(0);
  }

  45% {
    -webkit-transform: translateY(25px);
  }

  63% {
    -webkit-transform: translateY(15px);
  }

  75% {
    -webkit-transform: translateY(7px);
  }

  85% {
    -webkit-transform: translateY(4px);
  }

  95% {
    -webkit-transform: translateY(2px);
  }
}

.bounce-in-down {
  animation: 1.2s ease-out bounce-in-down;
}

@keyframes bounce-in-down {
  0% {
    transform: translateY(-1000px);
  }

  25% {
    transform: translateY(-50px);
  }

  30%, 55%, 70%, 80%, 90%, 100% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(-25px);
  }

  63% {
    transform: translateY(-15px);
  }

  75% {
    transform: translateY(-7px);
  }

  85% {
    transform: translateY(-4px);
  }

  95% {
    transform: translateY(-2px);
  }
}

@-webkit-keyframes bounce-in-down {
  0% {
    -webkit-transform: translateY(-1000px);
  }

  25% {
    -webkit-transform: translateY(-50px);
  }

  30%, 55%, 70%, 80%, 90%, 100% {
    -webkit-transform: translateY(0);
  }

  45% {
    -webkit-transform: translateY(-25px);
  }

  63% {
    -webkit-transform: translateY(-15px);
  }

  75% {
    -webkit-transform: translateY(-7px);
  }

  85% {
    -webkit-transform: translateY(-4px);
  }

  95% {
    -webkit-transform: translateY(-2px);
  }
}

.roll-in-right {
  animation: 1.2s roll-in-right;
}

@keyframes roll-in-right {
  0% {
    opacity: 0;
    transform: translateX(-100%)rotate(-130deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0%)rotate(0);
  }
}

@-webkit-keyframes roll-in-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%)rotate(-130deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%)rotate(0);
  }
}

.roll-in-left {
  animation: 1.2s roll-in-left;
}

@keyframes roll-in-left {
  0% {
    opacity: 0;
    transform: translateX(100%)rotate(130deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0%)rotate(0);
  }
}

@-webkit-keyframes roll-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%)rotate(130deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%)rotate(0);
  }
}

.roll-out-right {
  animation: 1.2s roll-out-right;
}

@keyframes roll-out-right {
  0% {
    opacity: 1;
    transform: translateX(0%)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%)rotate(130deg);
  }
}

@-webkit-keyframes roll-out-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%)rotate(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%)rotate(130deg);
  }
}

.roll-out-left {
  animation: 1.2s roll-out-left;
}

@keyframes roll-out-left {
  0% {
    opacity: 1;
    transform: translateX(0%)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%)rotate(-130deg);
  }
}

@-webkit-keyframes roll-out-left {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%)rotate(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-100%)rotate(-130deg);
  }
}

.rotbo-in-right {
  animation: 1.1s ease-out rotbo-in-right;
}

@keyframes rotbo-in-right {
  0% {
    transform: translateX(-1000px)rotateZ(-540deg);
  }

  63% {
    transform: translateX(15px)rotateZ(20deg);
  }

  75% {
    transform: translateX(-7px)rotateZ(-7deg);
  }

  85% {
    transform: translateX(4px)rotateZ(4deg);
  }

  95% {
    transform: translateX(-2px)rotateZ(-2deg);
  }

  100% {
    transform: translateX(0)rotateZ(0);
  }
}

@-webkit-keyframes rotbo-in-right {
  0% {
    -webkit-transform: translateX(-1000px)rotateZ(-540deg);
  }

  63% {
    -webkit-transform: translateX(15px)rotateZ(20deg);
  }

  75% {
    -webkit-transform: translateX(-7px)rotateZ(-7deg);
  }

  85% {
    -webkit-transform: translateX(4px)rotateZ(4deg);
  }

  95% {
    -webkit-transform: translateX(-2px)rotateZ(-2deg);
  }

  100% {
    -webkit-transform: translateX(0)rotateZ(0);
  }
}

.rotbo-in-left {
  animation: 1.1s ease-out rotbo-in-left;
}

@keyframes rotbo-in-left {
  0% {
    transform: translateX(1000px)rotateZ(540deg);
  }

  63% {
    transform: translateX(-15px)rotateZ(-20deg);
  }

  75% {
    transform: translateX(7px)rotateZ(7deg);
  }

  85% {
    transform: translateX(-4px)rotateZ(-4deg);
  }

  95% {
    transform: translateX(2px)rotateZ(2deg);
  }

  100% {
    transform: translateX(0)rotateZ(0);
  }
}

@-webkit-keyframes rotbo-in-left {
  0% {
    -webkit-transform: translateX(1000px)rotateZ(540deg);
  }

  63% {
    -webkit-transform: translateX(-15px)rotateZ(-20deg);
  }

  75% {
    -webkit-transform: translateX(7px)rotateZ(7deg);
  }

  85% {
    -webkit-transform: translateX(-4px)rotateZ(-4deg);
  }

  95% {
    -webkit-transform: translateX(2px)rotateZ(2deg);
  }

  100% {
    -webkit-transform: translateX(0)rotateZ(0);
  }
}

.rotbo-out-left {
  animation: 2s ease-out rotbo-out-left;
}

@keyframes rotbo-out-left {
  0% {
    transform: translateX(0%)rotateZ(0);
  }

  20% {
    transform: translateX(15px)rotateZ(20deg);
  }

  100% {
    transform: translateX(-2000px)rotateZ(-1080deg);
  }
}

@-webkit-keyframes rotbo-out-left {
  0% {
    -webkit-transform: translateX(0%)rotateZ(0);
  }

  20% {
    -webkit-transform: translateX(15px)rotateZ(20deg);
  }

  100% {
    -webkit-transform: translateX(-2000px)rotateZ(-1080deg);
  }
}

.rotbo-out-right {
  animation: 2s ease-out rotbo-out-right;
}

@keyframes rotbo-out-right {
  0% {
    transform: translateX(0%)rotateZ(0);
  }

  20% {
    transform: translateX(-15px)rotateZ(-20deg);
  }

  100% {
    transform: translateX(2000px)rotateZ(1080deg);
  }
}

@-webkit-keyframes rotbo-out-right {
  0% {
    -webkit-transform: translateX(0%)rotateZ(0);
  }

  20% {
    -webkit-transform: translateX(-15px)rotateZ(-20deg);
  }

  100% {
    -webkit-transform: translateX(2000px)rotateZ(1080deg);
  }
}

.flip {
  animation: 1.3s ease-out flip;
}

@keyframes flip {
  0% {
    transform: rotateY(-360deg);
  }

  40% {
    transform: scale(1.6)rotateY(-190deg);
  }

  50% {
    transform: scale(1.6)rotateY(-150deg);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes flip {
  0% {
    opacity: .2;
    -webkit-transform: perspective(500px)rotateX(70deg);
  }

  30% {
    -webkit-transform: perspective(500px)rotateX(-50deg);
  }

  45% {
    -webkit-transform: perspective(500px)rotateX(30deg);
  }

  65% {
    -webkit-transform: perspective(500px)rotateX(-20deg);
  }

  85% {
    -webkit-transform: perspective(500px)rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(500px)rotateX(0);
  }
}

.flip-in-x {
  animation: 1.2s flip-in-x;
}

@keyframes flip-in-x {
  0% {
    opacity: .2;
    transform: perspective(500px)rotateX(70deg);
  }

  30% {
    transform: perspective(500px)rotateX(-50deg);
  }

  45% {
    transform: perspective(500px)rotateX(30deg);
  }

  65% {
    transform: perspective(500px)rotateX(-20deg);
  }

  85% {
    transform: perspective(500px)rotateX(10deg);
  }

  100% {
    transform: perspective(500px)rotateX(0);
  }
}

@-webkit-keyframes flip-in-x {
  0% {
    opacity: .2;
    -webkit-transform: perspective(500px)rotateX(70deg);
  }

  30% {
    -webkit-transform: perspective(500px)rotateX(-50deg);
  }

  45% {
    -webkit-transform: perspective(500px)rotateX(30deg);
  }

  65% {
    -webkit-transform: perspective(500px)rotateX(-20deg);
  }

  85% {
    -webkit-transform: perspective(500px)rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(500px)rotateX(0);
  }
}

.flip-in-y {
  animation: 1.2s flip-in-y;
}

@keyframes flip-in-y {
  0% {
    opacity: .2;
    transform: perspective(500px)rotateY(70deg);
  }

  30% {
    transform: perspective(500px)rotateY(-50deg);
  }

  45% {
    transform: perspective(500px)rotateY(30deg);
  }

  65% {
    transform: perspective(500px)rotateY(-20deg);
  }

  85% {
    transform: perspective(500px)rotateY(10deg);
  }

  100% {
    transform: perspective(500px)rotateY(0);
  }
}

@-webkit-keyframes flip-in-y {
  0% {
    opacity: .2;
    -webkit-transform: perspective(500px)rotateY(70deg);
  }

  30% {
    -webkit-transform: perspective(500px)rotateY(-50deg);
  }

  45% {
    -webkit-transform: perspective(500px)rotateY(30deg);
  }

  65% {
    -webkit-transform: perspective(500px)rotateY(-20deg);
  }

  85% {
    -webkit-transform: perspective(500px)rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(500px)rotateY(0);
  }
}

.flip-out-x {
  animation: 1s flip-out-x;
}

@keyframes flip-out-x {
  0% {
    transform: perspective(500px)rotateX(0);
  }

  50% {
    opacity: 1;
    transform: perspective(500px)rotateX(-30deg);
  }

  100% {
    opacity: 0;
    transform: perspective(500px)rotateX(70deg);
  }
}

@-webkit-keyframes flip-out-x {
  0% {
    -webkit-transform: perspective(500px)rotateX(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: perspective(500px)rotateX(-30deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: perspective(500px)rotateX(70deg);
  }
}

.flip-out-y {
  animation: 1s flip-out-y;
}

@keyframes flip-out-y {
  0% {
    transform: perspective(500px)rotateY(0);
  }

  50% {
    opacity: 1;
    transform: perspective(500px)rotateY(-30deg);
  }

  100% {
    opacity: 0;
    transform: perspective(500px)rotateY(70deg);
  }
}

@-webkit-keyframes flip-out-y {
  0% {
    -webkit-transform: perspective(500px)rotateY(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: perspective(500px)rotateY(-30deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: perspective(500px)rotateY(70deg);
  }
}

.rotate {
  animation: 1s ease-in rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.rotate-in {
  animation: 1s ease-in rotate-in;
}

@keyframes rotate-in {
  0% {
    opacity: 0;
    transform: rotate(0);
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-in {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0);
  }

  80% {
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.rotate-out {
  animation: 1s ease-out rotate-out;
}

@keyframes rotate-out {
  0% {
    transform: rotate(-360deg);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(0);
  }
}

@-webkit-keyframes rotate-out {
  0% {
    -webkit-transform: rotate(-360deg);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate(0);
  }
}

.rotate-in-x {
  animation: 1s ease-in-out rotate-in-x;
}

@keyframes rotate-in-x {
  0% {
    opacity: 0;
    transform: rotateX(180deg);
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: rotateX(0);
  }
}

@-webkit-keyframes rotate-in-x {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(180deg);
  }

  80% {
    opacity: 1;
  }

  100% {
    -webkit-transform: rotateX(0);
  }
}

.rotate-out-x {
  animation: 1s ease-in-out rotate-out-x;
}

@keyframes rotate-out-x {
  0% {
    transform: rotateX(0);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotateX(180deg);
  }
}

@-webkit-keyframes rotate-out-x {
  0% {
    -webkit-transform: rotateX(0);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(180deg);
  }
}

.rotate-in-y {
  animation: 1s ease-in-out rotate-in-y;
}

@keyframes rotate-in-y {
  0% {
    opacity: 0;
    transform: rotateY(-180deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

@-webkit-keyframes rotate-in-y {
  0% {
    opacity: 0;
    -webkit-transform: rotateY(0);
  }

  60% {
    opacity: 1;
  }

  100% {
    -webkit-transform: rotateY(360deg);
  }
}

.rotate-out-y {
  animation: 1s ease-in-out rotate-out-y;
}

@keyframes rotate-out-y {
  0% {
    opacity: 1;
    transform: rotateY(0);
  }

  100% {
    opacity: 0;
    transform: rotateY(-180deg);
  }
}

@-webkit-keyframes rotate-out-y {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateY(-180deg);
  }
}

.rotate-in-3d {
  animation: 1s ease-in-out rotate-in-3d;
}

@keyframes rotate-in-3d {
  0% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, 0);
  }

  60% {
    opacity: 1;
  }

  100% {
    transform: rotate3d(1, 1, 0, 360deg);
  }
}

@-webkit-keyframes rotate-in-3d {
  0% {
    opacity: 0;
    -webkit-transform: rotate3d(1, 1, 0, 0);
  }

  60% {
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate3d(1, 1, 0, 360deg);
  }
}

.rotate-out-3d {
  animation: 1s ease-in-out rotate-out-3d;
}

@keyframes rotate-out-3d {
  0% {
    transform: rotate3d(1, 1, 0, 360deg);
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate3d(1, 1, 0, 0);
  }
}

@-webkit-keyframes rotate-out-3d {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 360deg);
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate3d(1, 1, 0, 0);
  }
}

.rotate-in-scale {
  animation: 1s rotate-in-scale;
  -webkit-animation-timing-function: easec;
  animation-timing-function: ease-in-out;
}

@keyframes rotate-in-scale {
  0% {
    opacity: 0;
    transform: scale(0)rotate(-540deg);
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: scale(1)rotate(0);
  }
}

@-webkit-keyframes rotate-in-scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0)rotate(-540deg);
  }

  80% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1)rotate(0);
  }
}

.rotate-out-scale {
  animation: 1s ease-in-out rotate-out-scale;
}

@keyframes rotate-out-scale {
  0% {
    transform: scale(1)rotate(0);
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(0)rotate(540deg);
  }
}

@-webkit-keyframes rotate-out-scale {
  0% {
    -webkit-transform: scale(1)rotate(0);
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0)rotate(540deg);
  }
}

.turn-in-x {
  animation: 1s ease-in turn-in-x;
}

@keyframes turn-in-x {
  0% {
    opacity: 0;
    transform: rotateX(360deg)scale(0);
  }

  100% {
    opacity: 1;
    transform: rotateX(0)scale(1);
  }
}

@-webkit-keyframes turn-in-x {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(360deg)scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(0)scale(1);
  }
}

.turn-out-x {
  animation: 1s ease-in turn-out-x;
}

@keyframes turn-out-x {
  0% {
    opacity: 1;
    transform: rotateX(0)scale(1);
  }

  80% {
    opacity: 0;
  }

  100% {
    transform: rotateX(360deg)scale(0);
  }
}

@-webkit-keyframes turn-out-x {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(0)scale(1);
  }

  80% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(360deg)scale(0);
  }
}

.turn-in-y {
  animation: 1s ease-in turn-in-y;
}

@keyframes turn-in-y {
  0% {
    opacity: 0;
    transform: rotateY(0)scale(0);
  }

  100% {
    opacity: 1;
    transform: rotateY(360deg)scale(1);
  }
}

@-webkit-keyframes turn-in-y {
  0% {
    opacity: 0;
    -webkit-transform: rotateY(0)scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(360deg)scale(1);
  }
}

.turn-out-y {
  animation: 1s ease-in turn-out-y;
}

@keyframes turn-out-y {
  0% {
    opacity: 1;
    transform: rotateY(0)scale(1);
  }

  80% {
    opacity: 0;
  }

  100% {
    transform: rotateY(360deg)scale(0);
  }
}

@-webkit-keyframes turn-out-y {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(0)scale(1);
  }

  80% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateY(360deg)scale(0);
  }
}

.back-in-right {
  animation: 1.5s ease-out back-in-right;
}

@keyframes back-in-right {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes back-in-right {
  0% {
    opacity: .7;
    -webkit-transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

.back-out-right {
  animation: 1.5s ease-in back-out-right;
}

@keyframes back-out-right {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

@-webkit-keyframes back-out-right {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    -webkit-transform: translateX(2000px)scale(.7);
  }
}

.back-in-left {
  animation: 1.5s ease-out back-in-left;
}

@keyframes back-in-left {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes back-in-left {
  0% {
    opacity: .7;
    -webkit-transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

.back-out-left {
  animation: 1.5s ease-in back-out-left;
}

@keyframes back-out-left {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

@-webkit-keyframes back-out-left {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    -webkit-transform: translateX(-2000px)scale(.7);
  }
}

.back-in-up {
  animation: 1.5s ease-out back-in-up;
}

@keyframes back-in-up {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes back-in-up {
  0% {
    opacity: .7;
    -webkit-transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

.back-out-up {
  animation: 1.5s ease-in back-out-up;
}

@keyframes back-out-up {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }
}

@-webkit-keyframes back-out-up {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    -webkit-transform: translateY(-1200px)scale(.7);
  }
}

.back-in-down {
  animation: 1.5s ease-out back-in-down;
}

@keyframes back-in-down {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes back-in-down {
  0% {
    opacity: .7;
    -webkit-transform: translateY(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

.back-out-down {
  animation: 1.5s ease-in back-out-down;
}

@keyframes back-out-down {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }
}

@-webkit-keyframes back-out-down {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    -webkit-transform: translateY(1200px)scale(.7);
  }
}

.push-in-up {
  transform-origin: 50% 100% 0;
  animation: 1s ease-in push-in-up;
}

@keyframes push-in-up {
  0% {
    opacity: 0;
    transform: rotateX(90deg);
  }

  100% {
    transform: none;
  }
}

@-webkit-keyframes push-in-up {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(90deg);
  }

  100% {
    -webkit-transform: none;
  }
}

.push-out-down {
  transform-origin: 50% 100% 0;
  animation: 1s ease-in push-out-down;
}

@keyframes push-out-down {
  0% {
    transform: none;
  }

  100% {
    opacity: 0;
    transform: rotateX(90deg);
  }
}

@-webkit-keyframes push-out-down {
  0% {
    -webkit-transform: none;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(90deg);
  }
}

.push-in-down {
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  animation: 1s ease-in push-in-down;
}

@keyframes push-in-down {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  100% {
    transform: none;
  }
}

@-webkit-keyframes push-in-down {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
  }

  100% {
    -webkit-transform: none;
  }
}

.push-out-up {
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  animation: 1s ease-in push-out-up;
}

@keyframes push-out-up {
  0% {
    transform: none;
  }

  100% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
}

@-webkit-keyframes push-out-up {
  0% {
    -webkit-transform: none;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
  }
}

.push-in-right {
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  transform-style: preserve-3d;
  animation: 1s ease-in push-in-right;
}

@keyframes push-in-right {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }

  100% {
    transform: none;
  }
}

@-webkit-keyframes push-in-right {
  0% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
  }

  100% {
    -webkit-transform: none;
  }
}

.push-out-left {
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  animation: 1s ease-in push-out-left;
}

@keyframes push-out-left {
  0% {
    transform: none;
  }

  100% {
    opacity: 0;
    transform: rotateY(90deg);
  }
}

@-webkit-keyframes push-out-left {
  0% {
    -webkit-transform: none;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
  }
}

.push-in-left {
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
  transform-style: preserve-3d;
  animation: 1s ease-in push-in-left;
}

@keyframes push-in-left {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  100% {
    transform: none;
  }
}

@-webkit-keyframes push-in-left {
  0% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
  }

  100% {
    -webkit-transform: none;
  }
}

.push-out-right {
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
  transform-style: preserve-3d;
  animation: 1s ease-in push-out-right;
}

@keyframes push-out-right {
  0% {
    transform: none;
  }

  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

@-webkit-keyframes push-out-right {
  0% {
    -webkit-transform: none;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
  }
}

.clip-x-in {
  animation: 1s ease-out clip-x-in;
}

@keyframes clip-x-in {
  0% {
    transform: scale(1, 0);
  }

  100% {
    transform: none;
  }
}

@-webkit-keyframes clip-x-in {
  0% {
    -webkit-transform: scale(1, 0);
  }

  100% {
    -webkit-transform: none;
  }
}

.clip-y-in {
  animation: 1s ease-out clip-y-in;
}

@keyframes clip-y-in {
  0% {
    transform: scale(0, 1);
  }

  100% {
    transform: none;
  }
}

@-webkit-keyframes clip-y-in {
  0% {
    -webkit-transform: scale(0, 1);
  }

  100% {
    -webkit-transform: none;
  }
}

.clip-xy-in {
  animation: 1s ease-out clip-xy-in;
}

@keyframes clip-xy-in {
  0% {
    transform: scale(0, .5);
  }

  50% {
    transform: scale(1, .5);
  }

  100% {
    transform: none;
  }
}

@-webkit-keyframes clip-xy-in {
  0% {
    -webkit-transform: scale(0, .5);
  }

  50% {
    -webkit-transform: scale(1, .5);
  }

  100% {
    -webkit-transform: none;
  }
}

.clip-x-out {
  animation: 1s ease-out clip-x-out;
}

@keyframes clip-x-out {
  0% {
    transform: none;
  }

  100% {
    transform: scale(1, 0);
  }
}

@-webkit-keyframes clip-x-out {
  0% {
    -webkit-transform: none;
  }

  100% {
    -webkit-transform: scale(1, 0);
  }
}

.clip-y-out {
  animation: 1s ease-out clip-y-out;
}

@keyframes clip-y-out {
  0% {
    transform: none;
  }

  100% {
    transform: scale(0, 1);
  }
}

@-webkit-keyframes clip-y-out {
  0% {
    -webkit-transform: none;
  }

  100% {
    -webkit-transform: scale(0, 1);
  }
}

.clip-xy-out {
  animation: 1s ease-out clip-xy-out;
}

@keyframes clip-xy-out {
  0% {
    transform: none;
  }

  50% {
    transform: scale(1, .5);
  }

  100% {
    transform: scale(0, .5);
  }
}

@-webkit-keyframes clip-xy-out {
  0% {
    -webkit-transform: none;
  }

  50% {
    -webkit-transform: scale(1, .5);
  }

  100% {
    -webkit-transform: scale(0, .5);
  }
}

.fanfare {
  animation: 1.2s fanfare;
}

@keyframes fanfare {
  0%, 100% {
    transform: scale(1);
  }

  30% {
    transform: scale(.8)rotateZ(5deg);
  }

  40%, 60%, 80% {
    transform: scale(1.15)rotateZ(-5deg);
  }

  50%, 70%, 90% {
    transform: scale(1.15)rotateZ(5deg);
  }
}

@-webkit-keyframes fanfare {
  0%, 100% {
    -webkit-transform: scale(1);
  }

  30% {
    -webkit-transform: scale(.8)rotateZ(5deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale(1.15)rotateZ(-5deg);
  }

  50%, 70%, 90% {
    -webkit-transform: scale(1.15)rotateZ(5deg);
  }
}

.float {
  animation: 1.6s infinite float;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@-webkit-keyframes float {
  0%, 100% {
    -webkit-transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-20px);
  }
}

.orbit {
  animation: 3s linear infinite orbit;
}

@keyframes orbit {
  0% {
    transform: rotate(0)translateX(20px)rotate(0);
  }

  100% {
    transform: rotate(360deg)translateX(20px)rotate(-360deg);
  }
}

@-webkit-keyframes orbit {
  0% {
    -webkit-transform: rotate(0)translateX(20px)rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg)translateX(20px)rotate(-360deg);
  }
}

.spiral {
  animation: 1s ease-out spiral;
}

@keyframes spiral {
  0% {
    transform: rotateY(-360deg)translateY(-150%);
  }

  100% {
    transform: rotateY(0)translateY(0);
  }
}

@-webkit-keyframes spiral {
  0% {
    -webkit-transform: rotateY(-360deg)translateY(-150%);
  }

  100% {
    -webkit-transform: rotateY(0)translateY(0);
  }
}

.bounce {
  animation: 1s ease-out bounce;
}

@keyframes bounce {
  0%, 30%, 55%, 70%, 80%, 90%, 100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-50px);
  }

  45% {
    transform: translateY(-25px);
  }

  63% {
    transform: translateY(-15px);
  }

  75% {
    transform: translateY(-7px);
  }

  85% {
    transform: translateY(-4px);
  }

  95% {
    transform: translateY(-2px);
  }
}

@-webkit-keyframes bounce {
  0%, 30%, 55%, 70%, 80%, 90%, 100% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-50px);
  }

  45% {
    -webkit-transform: translateY(-25px);
  }

  63% {
    -webkit-transform: translateY(-15px);
  }

  75% {
    -webkit-transform: translateY(-7px);
  }

  85% {
    -webkit-transform: translateY(-4px);
  }

  95% {
    -webkit-transform: translateY(-2px);
  }
}

.vibro {
  animation: .5s vibro;
}

@keyframes vibro {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(5px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(-5px);
  }
}

@-webkit-keyframes vibro {
  0%, 100% {
    -webkit-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(5px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(-5px);
  }
}

.heartbeat {
  animation: 8s ease-out infinite heartbeat;
}

@keyframes heartbeat {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: scale(1);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: scale(1.15);
  }
}

@-webkit-keyframes heartbeat {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transform: scale(1);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.15);
  }
}

.shake {
  animation: 1s ease-in shake;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    transform: translateX(20px);
  }

  25%, 50%, 75% {
    transform: translateX(-20px);
  }
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: translateX(20px);
  }

  25%, 50%, 75% {
    -webkit-transform: translateX(-20px);
  }
}

.puff {
  animation: 1s ease-out puff;
}

@keyframes puff {
  0% {
    transform: none;
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@-webkit-keyframes puff {
  0% {
    -webkit-transform: none;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
  }
}

.scale-in {
  animation: 1.5s scale-in;
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes scale-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

.scale-out {
  animation: 1s scale-out;
}

@keyframes scale-out {
  0% {
    opacity: 1;
    transform: none;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@-webkit-keyframes scale-out {
  0% {
    opacity: 1;
    -webkit-transform: none;
  }

  50% {
    opacity: .7;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0);
  }
}

.stretch {
  animation: 1s stretch;
}

@keyframes stretch {
  0%, 100% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.3, .7, 1);
  }

  40% {
    transform: scale3d(.7, 1.3, 1);
  }

  60% {
    transform: scale3d(1.2, .8, 1);
  }

  75% {
    transform: scale3d(.8, 1.2, 1);
  }

  90% {
    transform: scale3d(1.05, .95, 1);
  }
}

@-webkit-keyframes stretch {
  0%, 100% {
    -webkit-transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.3, .7, 1);
  }

  40% {
    -webkit-transform: scale3d(.7, 1.3, 1);
  }

  60% {
    -webkit-transform: scale3d(1.2, .8, 1);
  }

  75% {
    -webkit-transform: scale3d(.8, 1.2, 1);
  }

  90% {
    -webkit-transform: scale3d(1.05, .95, 1);
  }
}

.pulse {
  animation: 1s pulse;
}

@keyframes pulse {
  0% {
    transform: none;
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: none;
  }

  50% {
    -webkit-transform: scale(1.15);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.spin {
  -webkit-transform-origin: 50% 50% -200px;
  transform-origin: 50% 50% -200px;
  animation: 1s ease-in-out spin;
}

@keyframes spin {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(360deg);
  }
}

.spin-y {
  transform-origin: 0 0;
  animation: 1s spin-y;
}

@keyframes spin-y {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes spin-y {
  0% {
    -webkit-transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(360deg);
  }
}

.whirl {
  animation: 2s whirl;
}

@keyframes whirl {
  0% {
    transform: translateY(1000px)rotateY(0);
  }

  80% {
    transform: translateY(-100%)rotateY(720deg);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes whirl {
  0% {
    -webkit-transform: translateY(1000px)rotateY(0);
  }

  80% {
    -webkit-transform: translateY(-100%)rotateY(720deg);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

.wabble {
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  animation: 1.2s wabble;
}

@keyframes wabble {
  0%, 100% {
    transform: rotateZ(0);
  }

  12.5% {
    transform: rotateZ(-20deg);
  }

  25% {
    transform: rotateZ(18deg);
  }

  37.5% {
    transform: rotateZ(-15deg);
  }

  50% {
    transform: rotateZ(13deg);
  }

  62.5% {
    transform: rotateZ(-10deg);
  }

  75% {
    transform: rotateZ(7deg);
  }

  87.5% {
    transform: rotateZ(-3deg);
  }
}

@-webkit-keyframes wabble {
  0%, 100% {
    -webkit-transform: rotateZ(0);
  }

  12.5% {
    -webkit-transform: rotateZ(-20deg);
  }

  25% {
    -webkit-transform: rotateZ(18deg);
  }

  37.5% {
    -webkit-transform: rotateZ(-15deg);
  }

  50% {
    -webkit-transform: rotateZ(13deg);
  }

  62.5% {
    -webkit-transform: rotateZ(-10deg);
  }

  75% {
    -webkit-transform: rotateZ(7deg);
  }

  87.5% {
    -webkit-transform: rotateZ(-3deg);
  }
}
/*# sourceMappingURL=index.a4544faa.css.map */
